import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './components/Home';

import Bauaufmaß from './components/projects/Baufaumaß';
import Photogrammetrie from './components/projects/Photogrammetrie';
import Bestandsanalyse from './components/projects/Bestandsanalyse';
import Bauforschung from './components/projects/Bauforschung';
import DreiD from './components/projects/DreiD';
import Impressum from './components/Impressum';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/bauaufmaß" component={Bauaufmaß} />
          <Route path="/photogrammetrie" component={Photogrammetrie} />
          <Route path="/bestandsanalyse" component={Bestandsanalyse} />
          <Route path="/bauforschung" component={Bauforschung} />
          <Route path="/360°" component={DreiD} />
          <Route path="/impressum" component={Impressum} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
