import React from 'react';
import Navbar from './Navbar';
import Profil from './Profil';
import Kontakt from './Kontakt';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { TweenMax, Back } from 'gsap/TweenMax';

export default class Home extends React.Component {
  state = {
    homeDescription: true
  };

  componentDidMount() {
    TweenMax.staggerFromTo(
      '.landing-card-wrapper',
      0.5,
      { y: 250, opacity: 0 },
      { y: 0, opacity: 1, ease: Back.easeOut, delay: 1 },
      0.25
    );
  }

  render() {
    return (
      <div className="landing-page">
        <Navbar />

        <div className="width100v flexed justcenter aligncenter column landing-gradient" />
        <div className="landing-content">
          {/* {this.state.homeDescription ? ( */}
          <div className="landingDiv justcenter flexed column">
            <div className="landingLinks flexed spacearound">
              <div className="landing-card-wrapper">
                <Link
                  to="/bauaufmaß"
                  className="flexed aligncenter column landing-card"
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    src="https://res.cloudinary.com/dpcx0po55/image/upload/v1562594601/FitzekPancini/Webseite%202019_Bauaufmass/Burg%20Schwarzenfels/Burg_Schwarzenfels_vednbd.jpg"
                    alt=""
                  />
                  <h2 className="marginTop2">Bauaufmaß</h2>
                </Link>
              </div>
              <div className="landing-card-wrapper">
                <Link
                  to="/bauforschung"
                  className="flexed aligncenter column landing-card"
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    src="https://res.cloudinary.com/dpcx0po55/image/upload/v1562594410/FitzekPancini/Webseite%202019_Bauforschung/Schloss%20Steinau/Schloss_Steinau_g3xrqz.jpg"
                    alt=""
                  />
                  <h2 className="marginTop2">Bauforschung</h2>
                </Link>
              </div>
              <div className="landing-card-wrapper">
                <Link
                  to="/bestandsanalyse"
                  className="flexed aligncenter column landing-card"
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    src="https://res.cloudinary.com/dpcx0po55/image/upload/v1562594317/FitzekPancini/Webseite%202019_Bestandsanalyse/Krafthalle%20Alsdorf/Krafthalle_Alsdorf_hxszvl.jpg"
                    alt=""
                  />
                  <h2 className="marginTop2">Bestandsanalyse</h2>
                </Link>
              </div>
              <div className="landing-card-wrapper">
                <Link
                  to="/photogrammetrie"
                  className="flexed aligncenter column landing-card"
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    src="https://res.cloudinary.com/dpcx0po55/image/upload/v1562594203/FitzekPancini/Webseite%202019_Photogrammetrie/St%20Viktor%20Fassaden%20Schwerte/St_Viktor_Fassaden_Schwerte_ijj8zr.jpg"
                    alt=""
                  />
                  <h2 className="marginTop2">Photogrammetrie</h2>
                </Link>
              </div>
              {/* <Link
                to="/360"
                className="flexed aligncenter column landing-card"
                style={{ textDecoration: 'none' }}
              >
                <video
                  autoPlay
                  src="https://res.cloudinary.com/dpcx0po55/video/upload/v1562592912/FitzekPancini/3D/Muensterstrasse-22-LONG-INTRO-480p-MP4_b4rjz3.mp4"
                  alt=""
                  width="80%"
                  height="72%"
                />
                <h2 className="marginTop2">360°</h2>
              </Link> */}
            </div>
          </div>
          {/* ) : null} */}
          <Profil />
          <Kontakt />
          <Footer />
        </div>
      </div>
    );
  }
}
