import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

class Impressum extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        <div
          className="width100v maxContent paddingbot5 flexed justcenter aligncenter column"
          id="impressum"
        >
          <h1 className="marginBot2 marginTop6">Impressum</h1>
          <div
            className=" impressum containerDiv flexed spacearound aligncenter column padding1"
            // style={{ backgroundColor: '#CFDCD8', borderRadius: '10px' }}
          >
            <p>
              Angaben gemäß § 5 TMG: │ Ingenieurbüro Fitzek/Pancini │ Immermannstraße 30 │ 50931 Köln
              Telefon 0221 732 6922 │ Telefax 0221 732 6923 │ mail an: post(@)fitzekpancini.de
            </p>
            <p>
              Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE122675432
              Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: Gerhard Fitzek │ Immermannstraße 30 │
              50931 Köln
            </p>
            <p>Fotos: Fitzek / Pancini oder Copyright-Angabe</p>
            <h5>Haftung für Inhalte</h5>
            <p>
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
              den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
              überwachen oder nach Umständenzu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
              allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
              ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
              von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <h5>Haftung für Links</h5>
            <p>
              haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
              verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
              Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
              erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
              konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.{' '}
            </p>
            <h5>Urheberrecht</h5>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
              dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
              des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
              privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht
              vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
              werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
              Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen
            </p>
            <h5>Datenschutz</h5>
            <p>
              SSL-Verschlüsselung Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als
              Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
              Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an
              dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist,
              können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
            </p>
            <p>Quelle: e-recht24.de</p>
            <p>
              Hier finden Sie meine{' '}
              <a href="https://drive.google.com/file/d/1cM2Pc3WVHw_Xno9ySvsbiGTbT-OxC86g/view?usp=sharing">
                Datenschutzerklärung
              </a>
            </p>
            <p>Gerhard Fitzek © 2019</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Impressum;
