import React from 'react';

class Profil extends React.Component {
  render() {
    return (
      <div
        className="profil width100v maxContent paddingtopbot5 marginTop15 flexed justcenter aligncenter column"
        id="profil"
      >
        <div
          className="profilDiv flexed spacearound aligncenter column paddingbot2 paddingtop3"
          // style={{ backgroundColor: 'rgba(58, 96, 109, 0.7)' }}
        >
          <h1 className="flexed justcenter aligncenter marginBot2">Ingenieurbüro Fitzek/Pancini</h1>
          <div className="profilCol borderbot flexed justcenter aligncenter ">
            <div className="profileCards flexed justcenter aligncenter column">
              <img className="circle profileImage" style={{ opacity: '0.8' }} />
              <h3>Ana Alice Pancini</h3>
              <p className="textWidth textCenter">
                geb. 1962 Studium an der FAU Santos in Brasilien, Fachrichtung Architektur | Mitarbeit in
                der Denkmalpflege der Stadtverwaltung São Paulo | Aufbaustudium Denkmalpflege und
                Bauforschung an der Universität Bamberg | Seit 1990 Ingenieurbüro Fitzek / Pancini,
                Schwerpunkte in Bauaufmaß, Bauforschung und Bestandsanalyse an Baudenkmälern.
              </p>
            </div>
            <div className="profileCards flexed justcenter aligncenter column">
              <img className="circle profileImage" width="150rem" style={{ opacity: '0.8' }} />
              <h3>Gerhard Fitzek</h3>
              <p className="textWidth textCenter">
                Studium an der FH Köln, Fachrichtung Baubetrieb | Mitarbeit in verschiedenen
                Ingenieurbüros | Seit 1985 eigenes Büro für Bauleitung | Aufbaustudium Denkmalpflege und
                Bauforschung an der Universität Bamberg | Seit 1990 Ingenieurbüro Fitzek / Pancini,
                Schwerpunkte in Bauaufmaß, Bauforschung und Bestandsanalyse an Baudenkmälern.{' '}
              </p>
            </div>
          </div>
          <div className="collaborators marginTop2">
            <h5>Projektbezogene Kooperationen mit</h5>
            <p>
              Apostolos Aravidis, Architekt und Grafiker <br />
              Dr. Anna Skriver, Kunsthistorikerin und Sachverständige für Wandmalerei
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Profil;
