import React from 'react';
import Navbar from '.././Navbar';
import Footer from '.././Footer';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';

class DreiD extends React.Component {
  state = {
    projectWindow: false,
    currentCard: {}
  };

  showProjectWindow = el => {
    let card = el;
    console.log(card);

    this.setState({
      projectWindow: true,
      currentCard: card
    });
  };

  hideProjectWindow = event => {
    this.setState({
      projectWindow: false
    });
  };

  render() {
    let projectArr = [
      {
        name: 'Schmiede Billerbeck',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1565014001/FitzekPancini/3D/Muensterstrasse-22-Dollhouse-View_1_aog4i9.jpg',
        video:
          'https://res.cloudinary.com/dpcx0po55/video/upload/v1562592912/FitzekPancini/3D/Muensterstrasse-22-LONG-INTRO-480p-MP4_b4rjz3.mp4'
      },
      {
        name: 'Senhalser Höfe',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1565006259/FitzekPancini/3D/Altes-Winzerhaus-Senhalser-Hofe_1-Dollhouse-View_lnavmh.jpg',
        video:
          'https://res.cloudinary.com/dpcx0po55/video/upload/v1565006292/FitzekPancini/3D/Winzerhaus_Senhalser_Hoefe_dnua1h.mp4'
      }
    ];
    let displayProjects = projectArr.map((el, i) => {
      console.log(this.state.currentCard);

      return (
        <div key={i}>
          <Card className="cards paddingbot1" key={i}>
            <Card.Body className="flexed spacebetween aligncenter">
              <Card.Title style={{ margin: '0', fontSize: 17, fontWeight: 'bold' }}>{el.name}</Card.Title>
              <Button
                onClick={e => this.showProjectWindow(el)}
                style={{
                  width: '8rem',
                  height: 'max-content',
                  backgroundColor: '#556069',
                  border: 'none'
                }}
              >
                Details
              </Button>
            </Card.Body>
            <div className="flexed justcenter">
              <Card.Img
                variant="top"
                src={el.anzeigeBild}
                // style={{ width: '33rem' }}
                className="dreiDimg"
              />
            </div>
          </Card>
        </div>
      );
    });

    return (
      <div>
        <Navbar />
        <div className="dreid width100v" />
        <div className="width100v maxContent flexed justcenter aligncenter marginTop2">
          <div className="cardsDiv wrap evenly row marginTop2 marginBot4">
            {displayProjects}
            {this.displayCurrentCard()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  displayCurrentCard() {
    if (!this.state.projectWindow) return null;
    let { currentCard } = this.state;
    let { video } = currentCard;
    console.log(video);

    return (
      <div className="backgrounddimmed">
        <div className="projectdetails flexed justcenter aligncenter">
          <Carousel style={{ height: '', width: 'maxcontent' }} interval={null}>
            <Carousel.Item className="flexed">
              <video width="100%" height="100%" autoPlay src={video} />
              <Carousel.Caption>
                <Button variant="light" onClick={this.hideProjectWindow}>
                  Zurück
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
            );
          </Carousel>
        </div>
      </div>
    );
  }
}

export default DreiD;
