import React from 'react';
import Navbar from '.././Navbar';
import Footer from '.././Footer';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';

const BgImage = ({ src }) => {
  return (
    <div
      style={{
        height: '60vh',
        width: '60vw',
        backgroundImage: `url('${src}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    />
  );
};

class Photogrammetrie extends React.Component {
  state = {
    projectWindow: false,
    currentCard: {}
  };

  showProjectWindow = (e, el) => {
    let card = el;

    this.setState({
      projectWindow: true,
      currentCard: card
    });
  };

  hideProjectWindow = event => {
    this.setState({
      projectWindow: false
    });
  };

  render() {
    let projectArr = [
      {
        name: 'Denkmal Gustaf Grändgens Platz Düsseldorf',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594245/FitzekPancini/Webseite%202019_Photogrammetrie/Denkmal%20Gustaf%20Gr%C3%85ndgens%20Platz%20D%C3%85sseldorf/Denkmal_Gustaf_Gr%C3%85ndgens_Platz_D%C3%85sseldorf_vworta.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594261/FitzekPancini/Webseite%202019_Photogrammetrie/Denkmal%20Gustaf%20Gr%C3%85ndgens%20Platz%20D%C3%85sseldorf/P1200067_glstzf.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594253/FitzekPancini/Webseite%202019_Photogrammetrie/Denkmal%20Gustaf%20Gr%C3%85ndgens%20Platz%20D%C3%85sseldorf/P1200068_qkpi82.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594245/FitzekPancini/Webseite%202019_Photogrammetrie/Denkmal%20Gustaf%20Gr%C3%85ndgens%20Platz%20D%C3%85sseldorf/Denkmal_Gustaf_Gr%C3%85ndgens_Platz_D%C3%85sseldorf_vworta.jpg'
        ]
      },
      {
        name: 'Dom zu Köln Dreikönigsschrein',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594260/FitzekPancini/Webseite%202019_Photogrammetrie/Dom%20zu%20K%C3%AEln%20Dreik%C3%AEnigsschrein/Dom_zu_K%C3%AEln_Dreik%C3%AEnigsschrein_hborwu.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594260/FitzekPancini/Webseite%202019_Photogrammetrie/Dom%20zu%20K%C3%AEln%20Dreik%C3%AEnigsschrein/Dom_zu_K%C3%AEln_Dreik%C3%AEnigsschrein_hborwu.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594263/FitzekPancini/Webseite%202019_Photogrammetrie/Dom%20zu%20K%C3%AEln%20Dreik%C3%AEnigsschrein/Ansicht_C_Schatten_hokzoa.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594442/FitzekPancini/Webseite%202019_Photogrammetrie/Dom%20zu%20K%C3%AEln%20Dreik%C3%AEnigsschrein/Dom_zu_K%C3%AEln_Dreik%C3%AEnigenschrein_kopphd.jpg'
        ]
      },
      {
        name: 'Haus Ostendorfstraße Berlin',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594235/FitzekPancini/Webseite%202019_Photogrammetrie/Haus%20Ostendorfstra%C2%B7e%20Berlin/11_AS%C3%85d_Haus_Ostendorfstra_e_25_mjjfgs.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594239/FitzekPancini/Webseite%202019_Photogrammetrie/Haus%20Ostendorfstra%C2%B7e%20Berlin/Haus_Ostendorfstra_e_Berlin_K%C3%AEpenick_Teil_1_16_vfraab.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594226/FitzekPancini/Webseite%202019_Photogrammetrie/Haus%20Ostendorfstra%C2%B7e%20Berlin/Haus_Ostendorfstra_e_Berlin_yik40s.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594235/FitzekPancini/Webseite%202019_Photogrammetrie/Haus%20Ostendorfstra%C2%B7e%20Berlin/11_AS%C3%85d_Haus_Ostendorfstra_e_25_mjjfgs.jpg'
        ]
      },
      {
        name: 'Kaiserpfalz Gelnhausen',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594234/FitzekPancini/Webseite%202019_Photogrammetrie/Kaiserpfalz%20Gelnhausen/Kaiserpfalz_Gelnhausen_xnaymm.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594288/FitzekPancini/Webseite%202019_Photogrammetrie/Kaiserpfalz%20Gelnhausen/IMG_9563_e57nle.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594234/FitzekPancini/Webseite%202019_Photogrammetrie/Kaiserpfalz%20Gelnhausen/Kaiserpfalz_Gelnhausen_xnaymm.jpg'
        ]
      },
      {
        name: 'Kirche Sayn Fassaden',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594276/FitzekPancini/Webseite%202019_Photogrammetrie/Kirche%20Sayn%20Fassaden/Kirche_Sayn_Fassaden_o5rz8q.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594293/FitzekPancini/Webseite%202019_Photogrammetrie/Kirche%20Sayn%20Fassaden/P1140250_lhp6y3.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594291/FitzekPancini/Webseite%202019_Photogrammetrie/Kirche%20Sayn%20Fassaden/P1140184_ow4uyp.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594268/FitzekPancini/Webseite%202019_Photogrammetrie/Kirche%20Sayn%20Fassaden/Kirche_Sayn_Gebetsh%C3%91uschen_hu8ct7.jpg'
        ]
      },
      {
        name: 'Krypta St. Hyppolitus Helden',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594208/FitzekPancini/Webseite%202019_Photogrammetrie/Krypta%20St%20Hyppolitus%20Helden/Krypta_St_Hyppolitus_Helden_qauirp.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594211/FitzekPancini/Webseite%202019_Photogrammetrie/Krypta%20St%20Hyppolitus%20Helden/DSCF0044_zex0no.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594208/FitzekPancini/Webseite%202019_Photogrammetrie/Krypta%20St%20Hyppolitus%20Helden/Krypta_St_Hyppolitus_Helden_qauirp.jpg'
        ]
      },
      {
        name: 'Schloss Steinau Fassaden',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594211/FitzekPancini/Webseite%202019_Photogrammetrie/Schloss%20Steinau%20Fassaden/Schloss_Steinau_Fassaden_exdcvz.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594213/FitzekPancini/Webseite%202019_Photogrammetrie/Schloss%20Steinau%20Fassaden/72_WF_Orthofotos_Fassaden_4_aglhoy.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594201/FitzekPancini/Webseite%202019_Photogrammetrie/Schloss%20Steinau%20Fassaden/72_WF_Orthofotos_Fassaden_1_vg5k0t.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594211/FitzekPancini/Webseite%202019_Photogrammetrie/Schloss%20Steinau%20Fassaden/Schloss_Steinau_Fassaden_exdcvz.jpg'
        ]
      },
      {
        name: 'St Viktor Fassaden Schwerte',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594203/FitzekPancini/Webseite%202019_Photogrammetrie/St%20Viktor%20Fassaden%20Schwerte/St_Viktor_Fassaden_Schwerte_ijj8zr.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594213/FitzekPancini/Webseite%202019_Photogrammetrie/St%20Viktor%20Fassaden%20Schwerte/4_ST_VIKTOR_ABW_F_W-50_mL_r4ajit.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594203/FitzekPancini/Webseite%202019_Photogrammetrie/St%20Viktor%20Fassaden%20Schwerte/St_Viktor_Fassaden_Schwerte_ijj8zr.jpg'
        ]
      }
    ];

    let displayProjects = projectArr.map((el, i) => {
      let details = [];
      details.push(el.details);
      return (
        <div key={i}>
          <Card className="cards" key={i}>
            <Card.Body className="flexed spacebetween aligncenter">
              <Card.Title style={{ margin: '0', fontSize: 17, fontWeight: 'bold' }}>{el.name}</Card.Title>
              <Button
                onClick={e => this.showProjectWindow(e, el)}
                style={{
                  width: '8rem',
                  height: 'max-content',
                  backgroundColor: '#556069',
                  border: 'none'
                }}
              >
                Details
              </Button>
            </Card.Body>
            <Card.Img variant="top" src={el.anzeigeBild} />
          </Card>
        </div>
      );
    });

    return (
      <div>
        <Navbar />
        <div className="photogrammetrie width100v" />
        <div className="width100v maxContent flexed justcenter aligncenter marginTop2">
          <div className="cardsDiv wrap evenly row marginTop2 marginBot4">
            {displayProjects}
            {this.displayCurrentCard()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  displayCurrentCard() {
    if (!this.state.projectWindow) return null;
    let { currentCard } = this.state;
    let { projectpictures } = currentCard;

    return (
      <div className="backgrounddimmed">
        <div className="projectdetails flexed justcenter aligncenter">
          <Carousel style={{ height: 'inherit', width: 'maxcontent' }} interval={null}>
            {projectpictures.map((el, i) => {
              return (
                <Carousel.Item>
                  <BgImage src={projectpictures[i]} />
                  <Carousel.Caption>
                    <Button variant="light" onClick={this.hideProjectWindow}>
                      Zurück
                    </Button>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default Photogrammetrie;
