import React from 'react';

class Kontakt extends React.Component {
  render() {
    return (
      <div
        className="kontakt width100v maxContent paddingbot5 marginTop15 flexed justcenter aligncenter column"
        id="kontakt"
      >
        <div className="flexed spacearound aligncenter kontaktForm column">
          {/* <form
            action="mailto:theresapancini@gmx.de"
            method="post"
            enctype="text/plain"
            target="_blank"
            className="kontaktForm flexed spacearound aligncenter"
          > */}
          <h1 className="marginBot2">Kontakt</h1>
          <p className="kontakttext">
            Die vorgestellten Projekte stellen nur eine kleine Auswahl unserer Arbeit dar. Gerne stellen
            wir Ihnen unsere Projektbearbeitungen ausführlicher in einem persönlichem Gespräch vor. Wir
            freuen uns über Ihren Anruf.
          </p>{' '}
          <a
            href="mailto:post@fitzekpancini.de"
            style={{ marginBottom: '0', marginTop: '1rem', color: '#556069' }}
            className="nodecoration"
          >
            {' '}
            Mail: post(@)fitzekpancini.de
          </a>
          <a href="tel:+492217326922" style={{ color: '#556069' }} className="nodecoration">
            Tel: +49 221 7326922
          </a>
          {/* <input type="text" placeholder="Vorname" />
            <input type="text" placeholder="Nachname" />
            <input type="text" placeholder="Email" />
            <input type="text" placeholder="Telefonnummer" />
            <textarea
              name="tbox"
              cols="24"
              rows="4"
              wrap="soft"
              id="nachricht"
              placeholder="Nachricht"
            />
            <button id="submitbutton" type="submit">
              Senden
            </button>
          </form> */}
        </div>
      </div>
    );
  }
}

export default Kontakt;
