import React from 'react';
import Navbar from '.././Navbar';
import Footer from '.././Footer';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';

const BgImage = ({ src }) => {
  return (
    <div
      style={{
        height: '60vh',
        width: '60vw',
        backgroundImage: `url('${src}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    />
  );
};

class Bestandsanalyse extends React.Component {
  state = {
    projectWindow: false,
    currentCard: {}
  };

  showProjectWindow = (e, el) => {
    let card = el;

    this.setState({
      projectWindow: true,
      currentCard: card
    });
  };

  hideProjectWindow = event => {
    this.setState({
      projectWindow: false
    });
  };

  render() {
    let projectArr = [
      {
        name: 'Burg Schwarzenfels',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594343/FitzekPancini/Webseite%202019_Bestandsanalyse/Burg%20Schwarzenfels/Burg_Schwarzenfels_ifc3yg.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594349/FitzekPancini/Webseite%202019_Bestandsanalyse/Burg%20Schwarzenfels/Sammelmappe1_Seite_5_o7xnig.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594343/FitzekPancini/Webseite%202019_Bestandsanalyse/Burg%20Schwarzenfels/Sammelmappe1_Seite_2_wgcqsa.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594340/FitzekPancini/Webseite%202019_Bestandsanalyse/Burg%20Schwarzenfels/Sammelmappe1_Seite_4_n9hpnn.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594343/FitzekPancini/Webseite%202019_Bestandsanalyse/Burg%20Schwarzenfels/Burg_Schwarzenfels_ifc3yg.jpg'
        ]
      },
      {
        name: 'Halle 11 Köln',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594304/FitzekPancini/Webseite%202019_Bestandsanalyse/Halle%2011%20K%C3%AEln/Halle_11_K%C3%AEln_ibbm2a.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594291/FitzekPancini/Webseite%202019_Bestandsanalyse/Halle%2011%20K%C3%AEln/DSCN1476_uggzm4.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594318/FitzekPancini/Webseite%202019_Bestandsanalyse/Halle%2011%20K%C3%AEln/DSCN1421_umslxl.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594299/FitzekPancini/Webseite%202019_Bestandsanalyse/Halle%2011%20K%C3%AEln/DSCN1359_hbyln4.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594310/FitzekPancini/Webseite%202019_Bestandsanalyse/Halle%2011%20K%C3%AEln/DSCN0005_ibugpj.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594292/FitzekPancini/Webseite%202019_Bestandsanalyse/Halle%2011%20K%C3%AEln/DSCN1375_ez6wh8.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594304/FitzekPancini/Webseite%202019_Bestandsanalyse/Halle%2011%20K%C3%AEln/Halle_11_K%C3%AEln_ibbm2a.jpg'
        ]
      },
      {
        name: 'Krafthalle Alsdorf',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594317/FitzekPancini/Webseite%202019_Bestandsanalyse/Krafthalle%20Alsdorf/Krafthalle_Alsdorf_hxszvl.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594331/FitzekPancini/Webseite%202019_Bestandsanalyse/Krafthalle%20Alsdorf/DSCF1415_sz4e66.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594356/FitzekPancini/Webseite%202019_Bestandsanalyse/Krafthalle%20Alsdorf/IMG_9438_kycf0y.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594356/FitzekPancini/Webseite%202019_Bestandsanalyse/Krafthalle%20Alsdorf/35i_A_F_dfgowt.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594321/FitzekPancini/Webseite%202019_Bestandsanalyse/Krafthalle%20Alsdorf/P1140044_jzslqz.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594334/FitzekPancini/Webseite%202019_Bestandsanalyse/Krafthalle%20Alsdorf/detail_a_lclf5e.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594338/FitzekPancini/Webseite%202019_Bestandsanalyse/Krafthalle%20Alsdorf/Fi_01_20_tzyk7h.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594334/FitzekPancini/Webseite%202019_Bestandsanalyse/Krafthalle%20Alsdorf/Schadenskartierung_33_ygegg5.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594317/FitzekPancini/Webseite%202019_Bestandsanalyse/Krafthalle%20Alsdorf/Krafthalle_Alsdorf_hxszvl.jpg'
        ]
      },
      {
        name: 'Sprudelhof Bad Nauheim',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594363/FitzekPancini/Webseite%202019_Bestandsanalyse/Sprudelhof%20Badehaus/Sprudelhof_Badehaus_bodv2i.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594366/FitzekPancini/Webseite%202019_Bestandsanalyse/Sprudelhof%20Badehaus/Gro_er_Sprudel__9_dtrdh6.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594351/FitzekPancini/Webseite%202019_Bestandsanalyse/Sprudelhof%20Badehaus/Sprudelhof_pf4tjy.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594363/FitzekPancini/Webseite%202019_Bestandsanalyse/Sprudelhof%20Badehaus/Sprudelhof_Badehaus_bodv2i.jpg'
        ]
      }
    ];

    let displayProjects = projectArr.map((el, i) => {
      let details = [];
      details.push(el.details);
      return (
        <div key={i}>
          <Card className="cards" key={i}>
            <Card.Body className="flexed spacebetween aligncenter">
              <Card.Title style={{ margin: '0', fontSize: 17, fontWeight: 'bold' }}>{el.name}</Card.Title>
              <Button
                onClick={e => this.showProjectWindow(e, el)}
                style={{
                  width: '8rem',
                  height: 'max-content',
                  backgroundColor: '#556069',
                  border: 'none'
                }}
              >
                Details
              </Button>
            </Card.Body>
            <Card.Img variant="top" src={el.anzeigeBild} />
          </Card>
        </div>
      );
    });

    return (
      <div>
        <Navbar />
        <div className="bestandsanalyse width100v" />
        <div className="width100v maxContent flexed justcenter aligncenter marginTop2">
          <div className="cardsDiv wrap evenly row marginTop2 marginBot4">
            {displayProjects}
            {this.displayCurrentCard()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  displayCurrentCard() {
    if (!this.state.projectWindow) return null;
    let { currentCard } = this.state;
    let { projectpictures } = currentCard;

    return (
      <div className="backgrounddimmed">
        <div className="projectdetails flexed justcenter aligncenter">
          <Carousel style={{ height: 'inherit', width: 'maxcontent' }} interval={null}>
            {projectpictures.map((el, i) => {
              return (
                <Carousel.Item>
                  <BgImage src={projectpictures[i]} />
                  <Carousel.Caption>
                    <Button variant="light" onClick={this.hideProjectWindow}>
                      Zurück
                    </Button>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default Bestandsanalyse;
