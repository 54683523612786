import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class NavbarApp extends React.Component {
  render() {
    return (
      <div>
        <Navbar
          scrolling
          dark
          expand="lg"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.95)' }}
          fixed="top"
          className="absolute logocolor"
        >
          <div>
            <a href="/" className="logo flexed row paddingleftright1 aligncenter ">
              <img
                src="https://res.cloudinary.com/dpcx0po55/image/upload/v1560802252/FitzekPancini/fitpa_logo-fu%CC%88r-homepage-1-removebg-preview_bykuvs.png"
                alt="Logo"
                className="marginr1"
                height="50rem"
              />
              <div className="logoText flexed flex-start column">
                <h4 className="margin0 nodecoration ">Ingenieurbüro</h4>
                <h4 className="margin0 nodecoriation ">Fitzek/Pancini</h4>
              </div>
            </a>
          </div>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto width100p flexed flex-end paddingleftright1">
              <NavDropdown title="Leistungen" id="basic-nav-dropdown" className="fsize">
                <NavDropdown.Item>
                  <Link to="/bauaufmaß">Bauaufmaß</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/bauforschung">Bauforschung</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/bestandsanalyse">Bestandsanalyse</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/photogrammetrie">Photogrammetrie</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/360°">360°</Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/#profil">Profil</Nav.Link>
              <Nav.Link href="/#kontakt">Kontakt</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"
        />
      </div>
    );
  }
}

export default NavbarApp;
