import React from 'react';
import Navbar from '.././Navbar';
import Footer from '.././Footer';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';

const BgImage = ({ src }) => {
  return (
    <div
      style={{
        height: '60vh',
        width: '60vw',
        backgroundImage: `url('${src}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    />
  );
};

class Bauforschung extends React.Component {
  state = {
    projectWindow: false,
    currentCard: {}
  };

  showProjectWindow = (e, el) => {
    let card = el;

    this.setState({
      projectWindow: true,
      currentCard: card
    });
  };

  hideProjectWindow = event => {
    this.setState({
      projectWindow: false
    });
  };

  render() {
    let projectArr = [
      {
        name: 'Basilika St. Ursula Köln',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594369/FitzekPancini/Webseite%202019_Bauforschung/Basilika%20St%20Ursula%20K%C3%AEln/Basilika_St_Ursula_K%C3%AEln_kqqtwi.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594382/FitzekPancini/Webseite%202019_Bauforschung/Basilika%20St%20Ursula%20K%C3%AEln/l%C3%91ngsschnitt-E-E_keyhfl.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594388/FitzekPancini/Webseite%202019_Bauforschung/Basilika%20St%20Ursula%20K%C3%AEln/Erdgeschoss_mit_ausgrabung_lp9wi2.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594386/FitzekPancini/Webseite%202019_Bauforschung/Basilika%20St%20Ursula%20K%C3%AEln/Grundriss_Erdegeschoss_foltfg.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594369/FitzekPancini/Webseite%202019_Bauforschung/Basilika%20St%20Ursula%20K%C3%AEln/Basilika_St_Ursula_K%C3%AEln_kqqtwi.jpg'
        ]
      },
      {
        name: 'Burg Gerolstein Brunnenturm',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594388/FitzekPancini/Webseite%202019_Bauforschung/Burg%20Gerolstein%20Brunnenturm/Burg_Gerolstein_Brunnenturm_n92obx.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594445/FitzekPancini/Webseite%202019_Bauforschung/Burg%20Gerolstein%20Brunnenturm/1003220117_x5reup.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594407/FitzekPancini/Webseite%202019_Bauforschung/Burg%20Gerolstein%20Brunnenturm/1003220108_dys7vd.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594413/FitzekPancini/Webseite%202019_Bauforschung/Burg%20Gerolstein%20Brunnenturm/1003220104_kksyti.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594388/FitzekPancini/Webseite%202019_Bauforschung/Burg%20Gerolstein%20Brunnenturm/Burg_Gerolstein_Brunnenturm_n92obx.jpg'
        ]
      },
      {
        name: 'Burg Schwarzenfels',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594419/FitzekPancini/Webseite%202019_Bauforschung/Burg%20Schwarzenfels/Burg_Schwarzenfels_vbxjpr.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594450/FitzekPancini/Webseite%202019_Bauforschung/Burg%20Schwarzenfels/LB_11_maix4f.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594419/FitzekPancini/Webseite%202019_Bauforschung/Burg%20Schwarzenfels/Burg_Schwarzenfels_vbxjpr.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594422/FitzekPancini/Webseite%202019_Bauforschung/Burg%20Schwarzenfels/Burg_Schwarzenfels_Bergfried_bogiac.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594443/FitzekPancini/Webseite%202019_Bauforschung/Burg%20Schwarzenfels/SYSTEMATIKBEZEICHNUNG_PDF_042008_Seite_1_l8qbmn.jpg'
        ]
      },
      {
        name: 'Dom zu Köln Sakristeikeller',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594441/FitzekPancini/Webseite%202019_Bauforschung/Dom%20zu%20K%C3%AEln%20Sakristeikeller/Dom_zu_K%C3%AEln_Sakristeikeller_amgcfg.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594445/FitzekPancini/Webseite%202019_Bauforschung/Dom%20zu%20K%C3%AEln%20Sakristeikeller/Iso_Dom_Publikation_lng1lw.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594424/FitzekPancini/Webseite%202019_Bauforschung/Dom%20zu%20K%C3%AEln%20Sakristeikeller/1990_sakristeikeller_iso_f4wazi.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594441/FitzekPancini/Webseite%202019_Bauforschung/Dom%20zu%20K%C3%AEln%20Sakristeikeller/Dom_zu_K%C3%AEln_Sakristeikeller_amgcfg.jpg'
        ]
      },
      {
        name: 'Lettner Wetzlar',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594368/FitzekPancini/Webseite%202019_Bauforschung/Lettner%20Wetzlar/Lettner_Wetzlar_tasqru.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594368/FitzekPancini/Webseite%202019_Bauforschung/Lettner%20Wetzlar/Lettner_Wetzlar_tasqru.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1564412613/FitzekPancini/Webseite%202019_Bauforschung/Lettner%20Wetzlar/Lettner_Zeichnung_Abgabe-Erdgeschossgrundriss_25_ohzzkp.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1564412614/FitzekPancini/Webseite%202019_Bauforschung/Lettner%20Wetzlar/Lettner_Zeichnung_Abgabe-Querschnitt_25_g7896v.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1564412613/FitzekPancini/Webseite%202019_Bauforschung/Lettner%20Wetzlar/Lettner_Zeichnung_Abgabe-L%C3%A4ngsschnitt_25_eiu1xk.jpg'
        ]
      },
      {
        name: 'Wasserwerk Kernten',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594379/FitzekPancini/Webseite%202019_Bauforschung/Wasserwerk%20Kenten/Wasserwerk_Kenten_nuf1sl.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1564573904/FitzekPancini/Webseite%202019_Bauforschung/Wasserwerk%20Kenten/Wasserwerk_Kenten_1_omaoar.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594668/FitzekPancini/Webseite%202019_Bauforschung/Wasserwerk%20Kenten/IMG_2803_cpyfjg.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594379/FitzekPancini/Webseite%202019_Bauforschung/Wasserwerk%20Kenten/Wasserwerk_Kenten_nuf1sl.jpg'
        ]
      }
    ];

    let displayProjects = projectArr.map((el, i) => {
      let details = [];
      details.push(el.details);
      return (
        <div key={i}>
          <Card className="cards" key={i}>
            <Card.Body className="flexed spacebetween aligncenter">
              <Card.Title style={{ margin: '0', fontSize: 17, fontWeight: 'bold' }}>
                {el.name}
              </Card.Title>
              <Button
                onClick={e => this.showProjectWindow(e, el)}
                style={{
                  width: '8rem',
                  height: 'max-content',
                  backgroundColor: '#556069',
                  border: 'none'
                }}
              >
                Details
              </Button>
            </Card.Body>
            <Card.Img variant="top" src={el.anzeigeBild} />
          </Card>
        </div>
      );
    });

    return (
      <div>
        <Navbar />
        <div className="bauforschung width100v marginTop5" />
        <div className="width100v maxContent flexed justcenter aligncenter marginTop2">
          <div className="cardsDiv wrap evenly row marginTop2 marginBot4">
            {displayProjects}
            {this.displayCurrentCard()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  displayCurrentCard() {
    if (!this.state.projectWindow) return null;
    let { currentCard } = this.state;
    let { projectpictures } = currentCard;

    return (
      <div className="backgrounddimmed">
        <div className="projectdetails flexed justcenter aligncenter">
          <Carousel style={{ height: 'inherit', width: 'maxcontent' }} interval={null}>
            {projectpictures.map((el, i) => {
              return (
                <Carousel.Item>
                  <BgImage src={projectpictures[i]} />
                  <Carousel.Caption>
                    <Button variant="light" onClick={this.hideProjectWindow}>
                      Zurück
                    </Button>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  }
}
export default Bauforschung;
