import React from 'react';
import Navbar from '.././Navbar';
import Footer from '.././Footer';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';

const BgImage = ({ src }) => {
  return (
    <div
      style={{
        height: '60vh',
        width: '60vw',
        backgroundImage: `url('${src}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    />
  );
};

class Bauaufmaß extends React.Component {
  state = {
    projectWindow: false,
    currentCard: {}
  };

  showProjectWindow = (e, el) => {
    let card = el;

    this.setState({
      projectWindow: true,
      currentCard: card
    });
  };

  hideProjectWindow = event => {
    this.setState({
      projectWindow: false
    });
  };

  render() {
    let projectArr = [
      {
        name: 'Burg Schwarzenfels',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594601/FitzekPancini/Webseite%202019_Bauaufmass/Burg%20Schwarzenfels/Burg_Schwarzenfels_vednbd.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594601/FitzekPancini/Webseite%202019_Bauaufmass/Burg%20Schwarzenfels/Burg_Schwarzenfels_vednbd.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594597/FitzekPancini/Webseite%202019_Bauaufmass/Burg%20Schwarzenfels/Marstall_uwh4jg.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594630/FitzekPancini/Webseite%202019_Bauaufmass/Burg%20Schwarzenfels/Blick_von_Bergfried_o7h2ur.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594644/FitzekPancini/Webseite%202019_Bauaufmass/Burg%20Schwarzenfels/Raum_104_Wand_B_u6set6.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594617/FitzekPancini/Webseite%202019_Bauaufmass/Burg%20Schwarzenfels/L%C3%91ngsschnitt_2_pujit3.jpg'
        ]
      },
      {
        name: 'Haus Ostendorfstraße Berlin',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594546/FitzekPancini/Webseite%202019_Bauaufmass/Haus%20Ostendorfstra%C2%B7e%20Berlin/Haus_Ostendorfstra_e_Berlin_urtlyh.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594629/FitzekPancini/Webseite%202019_Bauaufmass/Haus%20Ostendorfstra%C2%B7e%20Berlin/Nordseite_ttgqwq.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594643/FitzekPancini/Webseite%202019_Bauaufmass/Haus%20Ostendorfstra%C2%B7e%20Berlin/Dachspitze_rakjcj.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594547/FitzekPancini/Webseite%202019_Bauaufmass/Haus%20Ostendorfstra%C2%B7e%20Berlin/Haus_Ostendorfstra_e_Berlin_Fassade_yzxgah.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594546/FitzekPancini/Webseite%202019_Bauaufmass/Haus%20Ostendorfstra%C2%B7e%20Berlin/Haus_Ostendorfstra_e_Berlin_urtlyh.jpg'
        ]
      },
      {
        name: 'Kloster Oelinghausen',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594567/FitzekPancini/Webseite%202019_Bauaufmass/Kloster%20Oehlinghausen%20Dachstuhl/Kloster_Oehlinghausen_1_oepmhh.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594616/FitzekPancini/Webseite%202019_Bauaufmass/Kloster%20Oehlinghausen%20Dachstuhl/Fassade_ybuiae.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594580/FitzekPancini/Webseite%202019_Bauaufmass/Kloster%20Oehlinghausen%20Dachstuhl/Fassade_Innenhof_mzniu8.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594601/FitzekPancini/Webseite%202019_Bauaufmass/Kloster%20Oehlinghausen%20Dachstuhl/Detail_Dachstuhl_p2saji.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594576/FitzekPancini/Webseite%202019_Bauaufmass/Kloster%20Oehlinghausen%20Dachstuhl/Kloster_Oehlinghausen_p6fen2.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594567/FitzekPancini/Webseite%202019_Bauaufmass/Kloster%20Oehlinghausen%20Dachstuhl/Kloster_Oehlinghausen_1_oepmhh.jpg'
        ]
      },
      {
        name: 'Michaelskirche Kiedrich',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594524/FitzekPancini/Webseite%202019_Bauaufmass/Michaelskirche%20Kiedrich/Michaelskirche_Kiedrich_ktosea.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594548/FitzekPancini/Webseite%202019_Bauaufmass/Michaelskirche%20Kiedrich/Michaelskirche_fhlofm.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594552/FitzekPancini/Webseite%202019_Bauaufmass/Michaelskirche%20Kiedrich/Chor_Au_en_p6x2az.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594551/FitzekPancini/Webseite%202019_Bauaufmass/Michaelskirche%20Kiedrich/Dachstuhl_nbipcw.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594524/FitzekPancini/Webseite%202019_Bauaufmass/Michaelskirche%20Kiedrich/Michaelskirche_Kiedrich_ktosea.jpg'
        ]
      },
      {
        name: 'Schloss Bad Homburg',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594451/FitzekPancini/Webseite%202019_Bauaufmass/Schloss%20Bad%20Homburg/Schloss_Bad_Homburg_jipkr1.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594462/FitzekPancini/Webseite%202019_Bauaufmass/Schloss%20Bad%20Homburg/Schloss_Bad_Homburg_2_foadps.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594480/FitzekPancini/Webseite%202019_Bauaufmass/Schloss%20Bad%20Homburg/K%C3%AEnigsfl%C3%85gel_Ansicht_West_oyc6uv.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594465/FitzekPancini/Webseite%202019_Bauaufmass/Schloss%20Bad%20Homburg/K%C3%AEnigsfl%C3%85gel_Querschnitt_3_yb27v5.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594451/FitzekPancini/Webseite%202019_Bauaufmass/Schloss%20Bad%20Homburg/Schloss_Bad_Homburg_jipkr1.jpg'
        ]
      },
      {
        name: 'St. Cyriakus Berghausen',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594654/FitzekPancini/Webseite%202019_Bauaufmass/St%20Cyriakus%20Berghausen/St_Cyriakus_Berghausen_wjlppp.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594658/FitzekPancini/Webseite%202019_Bauaufmass/St%20Cyriakus%20Berghausen/Au_enansicht_lyt8rv.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594663/FitzekPancini/Webseite%202019_Bauaufmass/St%20Cyriakus%20Berghausen/Gew%C3%AElbe_Wandmalerei_doufag.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594661/FitzekPancini/Webseite%202019_Bauaufmass/St%20Cyriakus%20Berghausen/Apsis_Wandmalereiabwicklung_y21y6u.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594649/FitzekPancini/Webseite%202019_Bauaufmass/St%20Cyriakus%20Berghausen/Querschnitt_1_gkppme.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594654/FitzekPancini/Webseite%202019_Bauaufmass/St%20Cyriakus%20Berghausen/St_Cyriakus_Berghausen_wjlppp.jpg'
        ]
      },
      {
        name: 'Turm Busdorfkirche',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594489/FitzekPancini/Webseite%202019_Bauaufmass/Turm%20Busdorfkirche/Turm_Busdorfkirche_bvtgoy.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594539/FitzekPancini/Webseite%202019_Bauaufmass/Turm%20Busdorfkirche/westfassade_u3fcfj.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594529/FitzekPancini/Webseite%202019_Bauaufmass/Turm%20Busdorfkirche/Fassade_audsni.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594511/FitzekPancini/Webseite%202019_Bauaufmass/Turm%20Busdorfkirche/Turminnenansicht_ni0ofx.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594489/FitzekPancini/Webseite%202019_Bauaufmass/Turm%20Busdorfkirche/Turm_Busdorfkirche_bvtgoy.jpg'
        ]
      },
      {
        name: 'Waggonhalle Köln',
        anzeigeBild:
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594482/FitzekPancini/Webseite%202019_Bauaufmass/Waggonhalle%20K%C3%AEln/Waggonhalle_K%C3%AEln_dglpad.jpg',
        projectpictures: [
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594518/FitzekPancini/Webseite%202019_Bauaufmass/Waggonhalle%20K%C3%AEln/Fassade_sp7rgc.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594504/FitzekPancini/Webseite%202019_Bauaufmass/Waggonhalle%20K%C3%AEln/Innenansicht_j10mtu.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594495/FitzekPancini/Webseite%202019_Bauaufmass/Waggonhalle%20K%C3%AEln/Detail_Fassade_gorzlf.jpg',
          'https://res.cloudinary.com/dpcx0po55/image/upload/v1562594482/FitzekPancini/Webseite%202019_Bauaufmass/Waggonhalle%20K%C3%AEln/Waggonhalle_K%C3%AEln_dglpad.jpg'
        ]
      }

      // you can add more here
    ];

    let displayProjects = projectArr.map((el, i) => {
      let details = [];
      details.push(el.details);
      return (
        <div key={i}>
          <Card className="cards" key={i}>
            <Card.Body className="flexed spacebetween aligncenter">
              <Card.Title style={{ margin: '0', fontSize: 17, fontWeight: 'bold' }}>
                {el.name}
              </Card.Title>
              <Button
                onClick={e => this.showProjectWindow(e, el)}
                style={{
                  width: '8rem',
                  height: 'max-content',
                  backgroundColor: '#556069',
                  border: 'none'
                }}
              >
                Details
              </Button>
            </Card.Body>
            <Card.Img variant="top" src={el.anzeigeBild} />
          </Card>
        </div>
      );
    });

    return (
      <div>
        <Navbar />
        <div className="bauaufmaß width100v marginTop4" />
        <div className="width100v maxContent flexed justcenter aligncenter marginTop2">
          <div className="cardsDiv wrap evenly row marginTop2 marginBot4">
            {displayProjects}
            {this.displayCurrentCard()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  displayCurrentCard() {
    if (!this.state.projectWindow) return null;
    let { currentCard } = this.state;
    let { projectpictures } = currentCard;

    return (
      <div className="backgrounddimmed">
        <div className="projectdetails flexed justcenter aligncenter">
          <Carousel style={{ height: 'inherit', width: 'maxcontent' }} interval={null}>
            {projectpictures.map((el, i) => {
              return (
                <Carousel.Item>
                  <BgImage src={projectpictures[i]} />
                  <Carousel.Caption>
                    <Button variant="light" onClick={this.hideProjectWindow}>
                      Zurück
                    </Button>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default Bauaufmaß;
