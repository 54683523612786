import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer width100v column flexed aligncenter spacebetween paddingtop15">
        <div className="respFooter width100v flexed spacearound textcolor footericons marginBot2">
          <Link to="/">Home</Link>
          <Link to="/bauaufmaß">Bauaufmaß</Link>
          <Link to="/bauforschung">Bauforschung</Link>
          <Link to="/Bestandsanalyse">Bestandsanalyse</Link>
          <Link to="/Photogrammetrie">Photogrammetrie</Link>
          <Link to="/360°">360°</Link>
          <Link to="/impressum">Impressum</Link>
        </div>
        <p className="darkblue">&#10084; made with love in Berlin</p>
      </div>
    );
  }
}

export default Footer;
